<template>
  <div>
    <v-text-field
      v-if="field.tipo === 'rut'"
      v-model="inputVal"
      :label="field.label"
      outlined
      :placeholder="field.placeholder"
      dense
      :rules="field.rules"
      return-masked-value
      :type="field.type"
      :prepend-inner-icon="field.icon"
      :filled="field.filled"
      :disabled="field.disabled"
      :readonly="field.readonly"
      :style="CssVars"
      :error-messages="field.error"
    ></v-text-field>
    <v-text-field
      v-if="field.tipo === 'phone'"
      v-model="field.text"
      :label="field.label"
      outlined
      :placeholder="field.placeholder"
      dense
      :rules="field.rules"
      return-masked-value
      :type="field.type"
      :prepend-inner-icon="field.icon"
      :filled="field.filled"
      :disabled="field.disabled"
      :readonly="field.readonly"
      :error-messages="field.error"
      :ref="field.ref"
      :style="cssVars2"
    ></v-text-field>
    <v-text-field
      v-else
      v-model="field.text"
      :label="field.label"
      outlined
      :placeholder="field.placeholder"
      dense
      :rules="field.rules"
      return-masked-value
      :type="field.type"
      :prepend-inner-icon="field.icon"
      :filled="field.filled"
      :disabled="field.disabled"
      :readonly="field.readonly"
      :error-messages="field.error"
      :style="cssVars2"
      :ref="field.ref"
    ></v-text-field>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "txt",
  props: ["fields", "model"],
  data() {
    return {
      field: this.fields,
    };
  },
  computed: {
    ...mapGetters("Users", ["getBotonColor"]),
    cssVars() {
      return {
        "--bg-color": this.inputVal === "" ? "#BEBEBE" : "#A9A9A9",
        "--bg-color-text": this.inputVal === "" ? "#A0A0A0" : "#A0A0A0",
        "--detail-color": this.detail_color,
      };
    },
    cssVars2() {
      return {
        "--bg-color": this.field.text === "" ? "#BEBEBE" : "#A9A9A9",
        "--bg-color-text": this.field.text === "" ? "#A0A0A0" : "#A0A0A0",
        "--detail-color": this.detail_color,
      };
    },

    inputVal: {
      get() {
        if (this.model !== "") {
          return this.format(this.model);
        } else return this.model;
      },
      set(val) {
        if (this.readonly === "false") {
          if (this.rut) {
            this.$emit("input", this.format(val));
          } else {
            this.$emit("input", val);
          }
        }
      },
    },
  },
  methods: {
    clean(rut) {
      return typeof rut === "string"
        ? rut.replace(/^0+|[^0-9kK]+/g, "").toUpperCase()
        : "";
    },

    format(rut) {
      rut = this.clean(rut);

      let result = rut.slice(-4, -1) + "-" + rut.substr(rut.length - 1);
      for (let i = 4; i < rut.length; i += 3) {
        result = rut.slice(-3 - i, -i) + "." + result;
      }

      return result;
    },
  },
  created() {
    this.$emit("fields", this.field);
  },
};
</script>

<style scoped>
@import "../../assets/CSS/TextField.css";
::v-deep.v-text-field fieldset,
.v-text-field.v-input--has-state fieldset {
  border: 1px solid var(--bg-color);
}
.v-input >>> label {
  color: var(--bg-color-text) !important;
}
.v-text-field >>> input {
  flex: 1 1 auto;
  line-height: 20px;
  padding: 8px 0 8px;
  max-width: 100%;
  min-width: 0px;
  width: 100%;
  font-size: 12px !important;
  font-family: Inter !important;
  color: #050c42 !important;
  background-color: transparent !important;
  border: 5px;
}
</style>
